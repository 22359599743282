<template>
  <navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenuVendor></sideMenuVendor>
              </div>
              <div class="col-lg-9">
                <div>
                  <h4 class="comm-title">Parked Auctions</h4>
                </div>
                <div v-if="!items.length">
                  <NotFound></NotFound>
                </div>
                <div v-else class="row">
                  <div
                    class="col-lg-4"
                    v-for="VehicleDetails in items"
                    :key="VehicleDetails.vehId"
                    style="margin-bottom: 15px; padding: 0px"
                  >
                    <div>
                      <div class="">
                        <!--new-main-tile-->
                        <section>
                          <div class="" style="margin: 0px 6px">
                            <div class="">
                              <div class="carTileWrap shine-overlay">
                                <div class="shine"></div>
                                <div
                                  class="cwrapImage"
                                  style="position: relative"
                                >
                                  <router-link
                                    :to="{
                                      name: 'VehDetails',
                                      params: {
                                        url_key: VehicleDetails.vehId,
                                      },
                                    }"
                                  >
                                    <img
                                      :src="path + VehicleDetails.vehImage1"
                                      class="img-fluid img-responsive border5px"
                                    />
                                  </router-link>

                                  <div class="topleft">
                                    <div>
                                      <CountDown
                                        :customstyle="'background-color: beige;'"
                                        :deadline="VehicleDetails.aucExtDate"
                                      ></CountDown>
                                    </div>
                                  </div>
                                  <div class="topright">
                                    <div style="display: flex"></div>
                                  </div>
                                </div>
                                <div>
                                  <div class="vehicleName">
                                    <router-link
                                      :to="{
                                        name: 'VehDetails',
                                        params: {
                                          url_key: VehicleDetails.vehId,
                                        },
                                      }"
                                    >
                                      <h5>
                                        {{ VehicleDetails.brandName }}
                                        {{ VehicleDetails.modelName }}
                                        {{ VehicleDetails.manYear }}
                                      </h5>
                                    </router-link>
                                  </div>
                                  <div>
                                    <!-- <div class="row" style="margin: 0px">
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-tachometer-alt pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>Odometer</span>
                                            <p class="fontWeightbidlist">
                                              {{ VehicleDetails.kmClocked }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-map-marker pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>Location</span>
                                            <p class="fontWeightbidlist">
                                              {{ VehicleDetails.locName }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i class="fas fa-car pdtListico"></i>
                                          <div class="pdtListicon">
                                            <span>Trim Level</span>
                                            <p class="fontWeightbidlist">
                                              Base
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-tachometer-alt pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>Disclosure</span>
                                            <p class="fontWeightbidlist">
                                              Not Available
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i class="fa fa-fire pdtListico"></i>
                                          <div class="pdtListicon">
                                            <span>Mileage</span>
                                            <p class="fontWeightbidlist">
                                              Not Actual
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-tachometer-alt pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>As Is</span>
                                            <p class="fontWeightbidlist">
                                              Not Available
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div> -->
                                    <hr style="margin: 0px" />
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="text-center">
                                          <div>
                                            <button
                                              class="tile-bid-now"
                                              tabindex="-1"
                                              @click="reAuction(VehicleDetails)"
                                            >
                                              Re-auction
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- .container -->
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="overlay-bid-btn parked-overlay"
                  v-if="openModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close"
                    @click="(openModel = false), (message = '')"
                  >
                    x
                  </button>

                  <h4 style="margin-bottom: 16px">Bid Information</h4>
                  <div class="">
                    <div class="row">
                      <div class="col-md-4">
                        <div>
                          <div>
                            <label>Brand </label>
                            <div class="form-group col">
                              <input
                                 type="number"
                                v-model="auctionEdit.brandName"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <div>
                            <label>VIN </label>
                            <div class="form-group col">
                              <input
                                type="text"
                                v-model="auctionEdit.vin"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <div>
                            <label>Bid Amount </label>
                            <div class="form-group col">
                              <input
                                type="text"
                                v-model="auctionEdit.LatestBidAmount"
                                class="form-control"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>
                          <div>
                            <label>Minimum Win Amount </label>
                            <div class="form-group col">
                              <input
                                type="number"
                                v-model="MinWinAmount"
                                class="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div>


                          <div>
                            <label>Auction Slots </label>
                            <div class="form-group col">
                              <select
                            name="dateTimeForAuctionSlot"
                            id="auctionSlotId"
                            class="auctionSlot form-control" 
                            v-model="selectedSlotId"
                          >
                            <option value="" disabled selected>
                              Select a auction slot
                            </option>
                            <option
                              v-for="auctionSlot in auctionSlotsList"
                              :key="auctionSlot.AuctionSlotId"
                              :value="auctionSlot.AuctionSlotId"
                            >
                              {{
                                auctionSlot.auctionSlotName                                
                              }}
                            </option>
                          </select>
                            </div>
                          </div>
                        </div>
                      </div>
                   
                     
                      <div class="col-md-4">
                      
                        <div>

                          <div class="form-group col">
                            <label for=""></label>
                            <button
                              style="width: 100%; margin-top: 12px"
                              class="btn btn-danger"
                              @click="auctionConfirm()"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="message"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ message }}
                  </div>
                </div>

                <div
                  class="overlay-bid-warning warningStyle"
                  v-if="openConfirmModel && userinfo"
                >
                  <button
                    class="close-bid-btn bid-popup-close btn_close"
                    @click="(openConfirmModel = false), (message = '')"
                  >
                    x
                  </button>
                  <h4>! Warning</h4>

                  <p>
                    Do you really want to start a new auction? This process
                    cannot be undone.
                  </p>
                  <div class="row">
                    <div class="col-6">
                      <div>
                        <button
                          class="bid-info-sub"
                          @click="openConfirmModel = false"
                        >
                          No
                        </button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div>
                        <button class="bid-info-sub" @click="startConfirm()">
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="auctionStatusMessage == 'Success'"
                  >
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                  <div class="form-group" v-else-if="auctionStatusMessage">
                    <div class="alert alert-warning" role="alert">
                      {{ auctionStatusMessage }}
                    </div>
                  </div>
                </div>
                <!-- <div class="">
                  <div
                    class="modalconfirm"
                    v-if="openConfirmModel && userinfo"
                  >
                    <div>
                      <div class="modal-dialog modal-confirm">
                        <div class="modal-content">
                          <div class="modal-header flex-column">
                            <div class="icon-box">
                              <i
                                class="fas fa-times"
                                style="
                                  font-size: 51px;
                                  color: red;
                                  margin-top: 10px;
                                "
                              ></i>
                            </div>
                            <h4 class="modal-title w-100">Are you sure?</h4>
                            <button
                              type="button"
                              class="close"
                              @click="openConfirmModel = false"
                              aria-hidden="true"
                            >
                              &times;
                            </button>
                          </div>
                          <div class="modal-body">
                            <p>
                              Do you really want to start a new auction? This
                              process cannot be undone. 
                                                           </p>
                          </div>
                          <div class="modal-footer justify-content-center">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              @click="openConfirmModel = false"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              @click="startConfirm()"
                            >
                              Yes
                            </button>
                          </div>
                          <div
                            v-if="message"
                            class="alert"
                            :class="
                              successful ? 'alert-success' : 'alert-danger'
                            "
                          >
                            {{ message }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>               -->
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation
                        :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout"
                      ></PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import navbar from "@/components/navbar.vue";
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import sideMenuVendor from "@/components/sideMenuVendor.vue";
import userService from "@/services/user.service";
import Config from "@/config";
//import moment from "moment";
export default {
  components: {
    sideMenuVendor,
    PageNation,
    NotFound,
    navbar,
  },
  data() {
    return {
      vechApproval: "",
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      perPage: 12,
      currentPage: 1,
      AuctionsDetails: [],
      items: [],
      openModel: false,
      openConfirmModel: false,
      auctionEdit: [],
      message: "",
      auctionAmount: null,
      aucEndDate: "",
      aucStartDate: "",
      MinWinAmount: "",
      MatDate: "",
      MinBidAmount: "",
      auctionSlotsList: [],
      selectedSlotId:"",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.sellerId != "undefined") {
        this.getSellerParkedAuctions(this.userinfo.sellerId);
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getAuctionTimeSlot() {
      userService
        .getAuctionTimeSlot()
        .then((response) => {
          //console.log("auction time slot", response.data.Data);

          this.auctionSlotsList = response.data.Data;
         
        })
        .catch((e) => {
          console.log(e);
        
        });
    },
    auctionConfirm() {
      if (this.MinWinAmount <= 0) {
        this.message = "";
        this.$toast.error("Please add auction amount", {
          position: "top",
          duration: 3000,
        });
      }else if (!this.selectedSlotId || this.selectedSlotId === "") {
        this.$toast.error("Please select slot ", {
          position: "top",
          duration: 3000,
        });
      } else {
        this.openConfirmModel = true;
        this.auctionEdit.MinWinAmount = this.MinWinAmount;
        this.openModel = false;
      }
    },
    startConfirm() {
      // console.log(moment(this.aucStartDate).format("YYYY-MM-DD H:mm:ss"));
      // console.log(moment(this.MatDate).format("YYYY-MM-DD H:mm:ss"));
      userService
        .createAuction(this.auctionEdit, this.userinfo.sellerId,this.selectedSlotId)
        .then((response) => {
          //console.log(response);
          this.message = response.data.Message;
          if (this.message == "Success") {
            this.$toast.success("Auction created suceesfully", {
              position: "top",
              duration: 3000,
            });
            var self = this;
            setTimeout(function () {
              self.closeModel();
            }, 2000);
          }
          // this.openConfirmModel = true;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.Message, {
            position: "top",
            duration: 3000,
          });
        });
    },

    closeModel() {
      this.message = "";
      this.openConfirmModel = false;
      this.openModel = false;
      location.reload();
    },
    reAuction(e) {
      this.getAuctionTimeSlot();
      this.auctionEdit = e;
      this.openModel = !this.openModel;
    },
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },
    getSellerParkedAuctions(sellerId) {
      let id = sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getSellerParkedAuctions(id, "Parked")
          .then((response) => {
            this.AuctionsDetails = response.data.Data;
            this.items = this.AuctionsDetails.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.overlay-bid-btn {
  /* position: fixed;
  z-index: 9999;
  top: 0%;
  left: 10%;
  padding: 20px 30px;
  background-color: rgb(226 240 248);
  position: fixed;
  width: 30%;
  margin: 150px 35%;
  border-radius: 5px; */
  /* position: fixed; */
  z-index: 9999;
  top: -7%;
  left: -25%;
  /* padding: 20px 30px; */
  background-color: rgb(255 255 255);
  position: fixed;
  width: 80%;
  /* margin: 143px 35%; */
  border-radius: 8px;
  box-shadow: 0px 0px 8px #0000005e;
}
.modal-vue .overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-vue .modal {
  position: relative;
  width: 300px;
  z-index: 9999;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
}

.modal-vue .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
/* .form-input {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}
.form-input :focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
} */

/* modal css */
.modal-confirm {
  color: #636363;
  width: 400px;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 2 6px;
  margin: 30px 0 -10px;
}
.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm .modal-body {
  color: #999;
}
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm .modal-footer a {
  color: #999;
}
.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}
.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}
.modal-confirm .btn-secondary {
  background: #c1c1c1;
}
.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-confirm .btn-danger {
  background: #f15e5e;
}
.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.modalconfirm {
  position: fixed;
  top: 20%;
  left: 40%;
  z-index: 9999999;
}
.overlay-bid-warning {
  position: fixed;
  z-index: 9999;
  top: 0%;
  left: 10%;
  padding: 20px 30px;
  background-color: rgb(226 240 248);
  position: fixed;
  width: 30%;
  margin: 150px 35%;
  border-radius: 5px;
}
</style>
